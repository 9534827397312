form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.date-banner {
  color: gray;
  text-shadow: 1px 1px black;
}

select {
  border-radius: 5px;
  margin: 2%;
}

input,
label {
  display: block;
}

label {
  color: black;
  font-size: 1.25em;
  margin-top: 1em;
  font-weight: 600;
}

input[type="text"] {
  border-radius: 5px;
  border: 1px solid #222227;
}

.submit-button {
  background-color: darkcyan;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 0.25em 0.5em;
  margin: 1em;
  font-weight: 700;
  text-shadow: 1px 1px black;
  box-shadow: 4px 3px rgba(0, 0, 0, 0.5);
}

.close-button {
  width: 5%;
  font-size: 1.5em;
  font-weight: 700;
  border: none;
  background-color: white;
  color: darkgray;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

.loaded-form {
  display: flex;
  flex-direction: column;
}

button:hover {
  box-shadow: 1px 2px rgba(0, 0, 0, 0.5);
}
