.calendar {
  // box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.4);
  width: 90%;
  border-radius: 5px;
  margin-top: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.month {
  background-color: #00edfe93;
  text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  font-size: 1rem;
  width: 100%;
  max-height: max-content;
  box-shadow: 0 0 5px #00edfe93;
}

.date {
  padding-bottom: 1em;
  font-size: 1.5vh;
  width: 35%;
}

.days-of-week {
  display: grid;
  width: 100%;
  background-color: #222227;
  grid-template-columns: repeat(7, calc(75vw / 7));
  justify-content: center;
  // grid-auto-rows: calc(75vw / 10);
  grid-auto-flow: row;
  text-align: center;
  grid-column-gap: 0.5em;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.4);
  div {
    align-self: center;
    font-size: 1.75vh;
    justify-self: center;
    padding: 1vw;
    width: 100%;
    font-weight: 600;
  }
}

.days {
  display: grid;
  width: 100%;
  background-color: #222227;
  grid-template-columns: repeat(7, calc(75vw / 7));
  justify-content: center;
  grid-auto-flow: row;
  text-align: center;
  grid-gap: 0.5em;
  padding: 0.5em 0;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;
  div {
    font-size: calc(75vw / 15);
    padding: 1vw;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
  }
  div:hover:not(.today, .selected, .off, .removed) {
    background-color: #7b7b82;
    box-shadow: 0 0 5px #dedee7;
    box-shadow: inset 2px 1.5px 2px #000000;
    cursor: pointer;
  }
}

.off {
  background-color: #fe436595;
  box-shadow: inset 2px 1.5px 2px #000000;
}

.selected {
  background-color: #464650;
  box-shadow: inset 2px 1.5px 2px #000000;
}

.remove {
  box-shadow: inset 2px 1.5px 2px #000000;
}

.middle {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}

.ul-box {
  width: 100%;
  height: 0.1rem;
  background-color: #222227;
  display: flex;
  justify-content: center;
}

.under-line {
  background-color: white;
  width: 80%;
}

// .middle::before {
//   content: "";
//   position: absolute;
//   background-color: #222227;
//   left: 10%;
//   height: 1px;
//   width: 80%;
//   border-bottom: 0.1rem solid white;
// }

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.request {
  width: 60%;
  max-width: 600px;
  padding: 1rem;
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid #fe4365;
  box-shadow: 0 0 6px #fe4365;
  color: white;
  text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  background-color: #fe4365;
}

.request:hover {
  box-shadow: 0;
}

.Sun {
  grid-column: 1;
}

.Mon {
  grid-column: 2;
}

.Tue {
  grid-column: 3;
}

.Wed {
  grid-column: 4;
}

.Thur {
  grid-column: 5;
}

.Fri {
  grid-column: 6;
}

.Sat {
  grid-column: 7;
}

.next,
.prev {
  font-size: 200%;
}

.prev-date,
.next-date {
  opacity: 0.5;
}

.today {
  background-color: #00edfe93;
  box-shadow: 0 0 5px #00edfe93;
  border: 3px solid #00edfe93;
}

.yes-no {
  font-size: 12px;
  font-weight: 600;
  width: 20%;
  color: white;
  text-shadow: 1px 1px black;
  background-color: #00edfe93;
  margin-top: 10px;
  padding: 2.5px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
}

@media only screen and (orientation: landscape) {
  .calendar {
    width: 45vw;
  }

  // .middle::after {
  //   content: "";
  //   position: absolute;
  //   background-color: #222227;
  //   left: 8%;
  //   height: 1px;
  //   width: 34%;
  //   border-bottom: 0.1rem solid white;
  // }

  .days {
    grid-template-columns: repeat(7, calc(40vw / 8));
    div {
      font-size: 2.5vh;
    }
  }

  .days-of-week {
    grid-template-columns: repeat(7, calc(40vw / 8));
    div {
      font-size: 2.25vh;
    }
  }

  // .days {
  //   grid-template-columns: repeat(7, calc(40vw / 8));
  //   grid-auto-rows: calc(40vw / 8);
  // }

  //     .days-of-week {
  //       grid-template-columns: 3vw 3vw 3vw 3vw 3vw 3vw 3vw;
  //     }
}
