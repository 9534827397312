.card-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 50%;
  max-height: 45vh;
  margin-top: 10px;
}

.card {
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  margin: 10px;
  text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5em;
  min-height: 25%;
  h3 {
    width: 40%;
  }
  span {
    width: 40%;
    text-align: right;
  }
  p {
    margin: 1vw 0;
  }
}

.event-title {
  width: 100%;
}

.event-users {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  padding: 3px;
}

dl {
  width: 100%;
  text-align: left;
}

dt {
  display: inline;
  font-weight: 600;
}

dd {
  margin-left: 3px;
}

.delete-button {
  background-color: transparent;
  border: none;
  color: white;
}

@media only screen and (orientation: landscape) {
  .card-container {
    width: 45vw;
    margin-right: 1.5rem;
    overflow-x: hidden;
    max-width: 600px;
    height: 90%;
    max-height: 95vh;
    grid-column: 2;
    grid-row: 1/2;
    height: 90%;
    max-height: 95vh;
    margin-top: 90px;
    align-self: center;
  }

  .card-container::-webkit-scrollbar {
    display: none;
  }

  .card {
    font-size: calc(80vh / 40);
    padding: 1vh;
  }
}
